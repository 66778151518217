
// 앱과 연결하는 브릿지 함수 
const AppBridge = {

  /**
   * 네티이브 여부
   * @returns {Boolean} 앱의 웨뷰 환경인지
   */
  isNative: function () {
    return AppBridge.isAndroid() || this.isIOS();
  },

  /**
   * 안드로이드 앱 웹뷰 여부
   * @returns {Boolean}
   */
  isAndroid: function () {
    return window.navigator.userAgent.indexOf('KeepFitOSa') >= 0;
  },

  /**
   * iOS 앱 웹뷰 여부
   * @returns {Boolean}
   */
  isIOS: function () {
    return window.navigator.userAgent.indexOf('KeepFitOSi') >= 0;
  },

  /**
   * 브릿지 미정의 처리
   * @param {Function} bridge 브릿지 콜
   * @param {Function} fallback 실패 처리 함수
   * @private
   */
  __innerBridgeCallHandle: function (bridge, fallback) {
    if (!this.isNative()) {
      fallback();
      return;
    }
    try {
      bridge();
    } catch (e) {
      console.log("브릿지 없음 " + e);
      fallback();
    }
  },
  // 단순 문구 전달
  trace: function (msg) {
    if (!this.isNative()) {
      return;
    }
    try {
      if (window.AppTrace) {
        AppTrace.postMessage(msg);
      } else if (window.webkit
        && window.webkit.messageHandlers
        && window.webkit.messageHandlers.AppTrace) {
        window.webkit.messageHandlers.AppTrace.postMessage(msg);
      } else {
        console.log("No Native API - AppTrace Found");
      }
    } catch (e) {
      console.log(e);
    }
  },
    
  // 앱에서 주소 열기
  openInApp: function (path, fnOnNotNative) {
    this.__innerBridgeCallHandle(function () {
      var msg = JSON.stringify({path: path});
      if (window.OpenInApp) {
        window.OpenInApp.postMessage(msg);
      } else {
        window.webkit.messageHandlers.OpenInApp.postMessage(msg);
      }
    }, fnOnNotNative);
  },
  // 설문조사
  keepfitPlusSurveyOpen: function (path, fnOnNotNative) {
    this.__innerBridgeCallHandle(function () {
      var msg = JSON.stringify({path: path});
      if (window.KeepfitPlusSurveyOpen) {
        window.KeepfitPlusSurveyOpen.postMessage(msg);
      } else {
        window.webkit.messageHandlers.KeepfitPlusSurveyOpen.postMessage(msg);
      }
    }, fnOnNotNative);
  },

  keepfitPlusSurveyClose: function (fnOnNotNative) {
    this.__innerBridgeCallHandle(function () {
      let msg = "";
      if (window.KeepfitPlusSurveyClose) {
        window.KeepfitPlusSurveyClose.postMessage(msg);
      } else {
        window.webkit.messageHandlers.KeepfitPlusSurveyClose.postMessage(msg);
      }
    }, fnOnNotNative);
  },

  loginRequest: function (fnOnNotNative) {
    this.__innerBridgeCallHandle(function () {
      var msg = "";
      if (window.LoginRequest) {
        window.LoginRequest.postMessage(msg);
      } else {
        window.webkit.messageHandlers.LoginRequest.postMessage(msg);
      }
    }, fnOnNotNative);
  },

  loginViaPurchaseClass: function (classId, price, fnOnNotNative) {
    let cid = parseInt(classId, 10);
    if (!cid) {
      fnOnNotNative();
      return;
    }
    let salePrice = parseInt(price, 10);
    this.__innerBridgeCallHandle(function () {
      var msg = JSON.stringify({classId: cid, price: salePrice ? salePrice : 0 });
      if (window.LoginViaPurchaseClass) {
        window.LoginViaPurchaseClass.postMessage(msg);
      } else {
        window.webkit.messageHandlers.LoginViaPurchaseClass.postMessage(msg);
      }
    }, fnOnNotNative);
  },

  /**
   * 클래스 상세 열기
   * @param {Number} classId
   * @param {String} path
   * @param {Function} fnOnNotNative
   */
  openClassDetail: function (classId, path, fnOnNotNative) {
    let cid = parseInt(classId, 10);
    if (!path || !cid) {
      fnOnNotNative();
      return;
    }
    this.__innerBridgeCallHandle(function () {
      let msg = JSON.stringify({classId: cid, inWeb: 1, path: path});
      if (window.OpenClassDetail) {
        window.OpenClassDetail.postMessage(msg);
      } else {
        window.webkit.messageHandlers.OpenClassDetail.postMessage(msg);
      }
    }, fnOnNotNative);
  },

  classPaymentStart: function (classId, path, fnOnNotNative) {
    let cid = parseInt(classId, 10);
    if (!cid) {
      fnOnNotNative();
      return;
    }
    this.__innerBridgeCallHandle(function () {
      var msg = JSON.stringify({classId: cid, inWeb: 1, path: path});
      if (window.ClassPaymentStart) {
        window.ClassPaymentStart.postMessage(msg);
      } else {
        window.webkit.messageHandlers.ClassPaymentStart.postMessage(msg);
      }
    }, fnOnNotNative);
  },

  classPaymentCompleted: function (classId, userClassId, fnOnNotNative) {
    let cid = parseInt(classId, 10);
    let ucid = parseInt(userClassId, 10);
    if (!cid || !ucid) {
      fnOnNotNative();
      return;
    }
    this.__innerBridgeCallHandle(function () {
      var msg = JSON.stringify({classId: cid, ucid: ucid});
      if (window.ClassPaymentCompleted) {
        window.ClassPaymentCompleted.postMessage(msg);
      } else {
        window.webkit.messageHandlers.ClassPaymentCompleted.postMessage(msg);
      }
    }, fnOnNotNative);
  },

  classPaymentFailed: function (classId, fnOnNotNative) {
    let cid = parseInt(classId, 10);
    if (!cid) {
      fnOnNotNative();
      return;
    }
    this.__innerBridgeCallHandle(function () {
      var msg = JSON.stringify({classId: cid});
      if (window.ClassPaymentFailed) {
        window.ClassPaymentFailed.postMessage(msg);
      } else {
        window.webkit.messageHandlers.ClassPaymentFailed.postMessage(msg);
      }
    }, fnOnNotNative);
  },

  /**
   * 아프리카 로그인 결과 전달
   * @param {BigInt} id
   * @param {String} nickname
   * @param {Boolean} noUser
   * @param {Function} fnOnNotNative
   */
  afreecaLogin: function (id, nickname, noUser, fnOnNotNative) {
    this.__innerBridgeCallHandle(function () {
      let msg = JSON.stringify({id: id, nickname: nickname, noUser: noUser});
      if (window.AfreecaLogin) {
        window.AfreecaLogin.postMessage(msg);
      } else {
        window.webkit.messageHandlers.AfreecaLogin.postMessage(msg);
      }
    }, fnOnNotNative);
  }
};

